import React from "react";
import "./ComingSoon.css";

const ComingSoon = () => {
  return (
    <>
      <div className="container">
        <img src="images/ambigoo_logo.png" alt="ambigoo studio" />
        <video
          autoPlay
          loop
          muted
          playsInline
          preload="auto"
          alt="website coming soon"
        >
          <source src="videos/coming_soon_1.mp4" type="video/mp4" />
        </video>
      </div>
    </>
  );
};

export default ComingSoon;
