import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import ComingSoon from "./ComingSoon";

function App() {
  return (
    <Router>
      <Routes>
        {/* Main route */}
        <Route path="/" element={<ComingSoon />} />

        {/* Catch-all route */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}

export default App;
